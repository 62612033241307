import * as React from "react";
import { useRef } from "react";
import { useState, Fragment } from "react";
import ReactMapGL, { Marker, FlyToInterpolator } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "./styles/map.css";
import * as turf from "@turf/turf";
import * as d3 from "d3-ease";
import TeamPage from "./TeamPage";
import axios from "axios";
import { api_credentials } from "../var.js";
import Footer from "./Footer";
import bottomBarImg from "../assets/images/bottom-bar-black.svg";
import bottomBarWhiteImg from "../assets/images/bottom-bar-white.svg";
import satelliteOff from "../assets/images/toggles/satellite-off.svg";
import satelliteOn from "../assets/images/toggles/satellite-on.svg";
import driversList from "../assets/images/toggles/driverlist-on.svg";
import LoadingSpinner from "./LoadingSpinner";
import io from "socket.io-client";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const mapStyles = {
  transilvania: {
    id: 0,
    classic: "mapbox://styles/ecogptest/ck8hdq3530kwq1ili5gyk41zj",
    satellite: "mapbox://styles/ecogptest/ck8qxu0yz0oi71ip8plskrvos",
  },
  schotten: {
    id: 1,
    classic: "mapbox://styles/ecogptest/cl4i4fe3n000q14qvqspybw04",
    satellite: "mapbox://styles/ecogptest/cl4i4nlar000b14lobiivsvvc",
  },
  calafat: {
    id: 2,
    classic: "mapbox://styles/ecogptest/cl9fmnxj600fj14pi5n7xgvpc",
    satellite: "mapbox://styles/ecogptest/cl9fmxn99007615ogvy8e3muk",
  },
  meppen: {
    id: 3,
    classic: "mapbox://styles/ecogptest/cla24wfy0009j15phjonf3w1o",
    satellite: "mapbox://styles/ecogptest/cla27h56c005s14nujyor1abv",
  },
};
const acutalMap = 0;
const CIRCUIT_LENGTH = 2.54; // km
const getObject = (object, id) => {
  for (let i = 0; i < Object.keys(object).length; i++) {
    const _style = Object.values(object)[i];
    if (_style.id === id) {
      return _style;
    }
  }
};
const socket = io(api_credentials.socketURL, {
  autoConnect: false,
});
function Map() {
  window.mobileCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        // eslint-disable-next-line no-useless-escape
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const body = {
    key: api_credentials.key,
    api_user: api_credentials.user,
  };

  const PHONE_SPONSOR_LINK =
    "https://www.chargeholidays.com/charge-holidays-app";
  const zoomDef = 15.5;
  const centerBuc = [44.436360692657104, 26.09860702876142];
  const centerTransilvania = [46.4326546146782, 24.422996546724818];
  const centerSchotten = [50.51886292321004, 9.15293678440625];
  const centerPalat = [44.42749197365006, 26.085262735031876];
  const centerCzechRepublic = [50.24573632755954, 15.837449392590088];
  const centerCalaft = [40.93287906798028, 0.8416142051397912];
  const centerMeppen = [52.75702325783905, 7.281574134692818];
  const centerFrance = [42.703362, 2.898368];
  const centers = [
    centerBuc,
    centerTransilvania,
    centerSchotten,
    centerPalat,
    centerCzechRepublic,
    centerCalaft,
    centerMeppen,
    centerFrance,
  ];
  const isMobile = window.mobileCheck();
  const defView = {
    width: window.innerWidth,
    height: window.innerHeight,
    latitude: centers[3][0],
    longitude: centers[3][1],
    zoom: zoomDef,
  };
  const zoomLevels = [
    [0, 78271.484],
    [1, 39135.742],
    [2, 19567.871],
    [3, 9783.936],
    [4, 4891.968],
    [5, 2445.984],
    [6, 1222.992],
    [7, 611.496],
    [8, 305.748],
    [9, 152.874],
    [10, 76.437],
    [11, 38.218],
    [12, 19.109],
    [13, 9.555],
    [14, 4.777],
    [15, 2.389],
    [16, 1.194],
    [17, 0.597],
    [18, 0.299],
    [19, 0.149],
    [20, 0.075],
    [21, 0.037],
    [22, 0.019],
  ];
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [follow, setFollow] = useState([]);
  const [center, setCenter] = useState([]);
  const [zoomL, setZoomL] = useState(zoomDef);
  const [teams, setTeams] = useState([]);
  const [tpProps, setTpProps] = useState();
  const [open, setOpen] = useState(false);
  const [teamInfo, setTeamInfo] = useState([]);
  const [raceFlag, setRaceFlag] = useState("green");
  const [mapStyle, setMapStyle] = useState(
    () => getObject(mapStyles, acutalMap).classic
  );
  const [viewport, setViewport] = useState(defView);
  const [bottomBar, setBottomBar] = useState(bottomBarImg);
  const [satelliteStyle, setSatelliteStyle] = useState(satelliteOff);
  const [teamsInfos, setTeamsInfos] = useState(new Array(100).fill("0"));
  const [isHidden, setIsHidden] = useState(false);
  const [penaltyPoints, setPenaltyPoints] = useState(new Array(100).fill("0"));
  const [loading, setLoading] = useState(false);
  const [raceTime, setRaceTime] = useState();
  const [raceTimeText, setRaceTimeText] = useState("Time Left");
  const [raceName, setRaceName] = useState("ecoGP");
  const [ticker, setTicker] = useState("welcome to ecoGP");
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [centerTeam, setCenterTeam] = useState(false);

  const handleInfo = (msg) => {
    createMarkers(msg[0], "pos", msg[2]);

    setRaceFlag(msg[1]);
    setTeamsInfos(msg[2]);
    setPenaltyPoints(msg[3]);
    setRaceTimeText(
      msg[4][1] === -1
        ? "Race ended :"
        : msg[4][1] === 1
        ? "Race start in :"
        : "Race time :"
    );
    setRaceTime(
      msg[4][1] === -1
        ? sec2time(msg[4][0]) + " ago."
        : msg[1] === 1
        ? sec2time(msg[4][0])
        : sec2time(msg[4][0])
    );
    setRaceName(msg[5]);
    setTicker(msg[6]);

    setLoading(true);
  };
  const handleMarkersAnimanation = (animationStatus) => {
    const overlayMarkers = Array.from(
      document.getElementsByClassName("overlays")[0]?.children || []
    );
    overlayMarkers.forEach((marker) => {
      animationStatus
        ? marker.classList.add("transition-marker")
        : marker.classList.remove("transition-marker");
    });
  };
  React.useEffect(() => {
    socket.connect();
    loadHandler();
    window.addEventListener("resize", () => {
      let vw = viewport;
      if (isMobile) {
        vw.height = document.documentElement.clientHeight;
        vw.width = document.documentElement.clientWidth;
      } else {
        vw.height = window.innerHeight;
        vw.width = window.innerWidth;
      }
      setViewport(vw);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    socket.on("connect", () => {
      setIsConnected(true);
      console.log("connected");
      socket.emit("start-timer");
      setTimeout(() => {
        handleMarkersAnimanation(true);
      }, 2000);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });
    socket.on("client-response", (res) => {
      let msg = res.data;
      handleInfo(msg);
    });
    socket.on("race-time", (res) => {
      let msg = res.data;
      console.log(msg);
      setRaceTime(
        msg[1] === -1
          ? sec2time(msg[0]) + " ago."
          : msg[1] === 1
          ? sec2time(msg[0])
          : sec2time(msg[0])
      );

      setRaceTimeText(
        msg[1] === -1
          ? "Race ended :"
          : msg[1] === 1
          ? "Race start in :"
          : "Race time :"
      );
    });

    let interval = setInterval(() => {
      console.log(socket.connected, "socket.connected");
      if (!socket.connected) {
        socket.connect();
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      socket.disconnect();
      socket.removeAllListeners();
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (center.length > 0) {
        handleMarkersAnimanation(false);
        let btns = document.querySelectorAll(".devices-col-btn");
        for (let i = 0; i < teams.length; i++) {
          btns[i].children[0].id = "off";
          for (let j = 0; j < follow.length; j++) {
            if (follow[j][0] === teams[i][1]) {
              btns[i].children[0].id = "on";
            }
          }
        }
        zoomTo(follow, 1000, d3.easeLinear, zoomL);
      }
    }, 2000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, zoomL]);

  React.useEffect(() => {
    handleMarkersAnimanation(false);
    const followTeam01 = document?.getElementsByName("229LUL2020000001")[0];
    console.log(centerTeam, followTeam01, undefined === true);
    if (!centerTeam && followTeam01) {
      followTeam01?.click();
      setCenterTeam(true);
    }

    setTimeout(() => {
      handleMarkersAnimanation(true);
    }, 2000);
  }, [teams]);
  const onScroll = () => {
    // let btns = document.querySelectorAll(".devices-col-btn");
    // for (let i = 0; i < btns.length; i++) {
    //   if (btns[i].children[0].id === "on") {
    //     btns[i].children[0].click();
    //   }
    // }
    // if (follow.length > 0){

    let zl = viewport.zoom;
    if (zl !== zoomL) {
      setZoomL(zl);
    }

    // }
  };

  const arrayEv = (evString) => {
    return JSON.parse(evString.data.replaceAll("'", '"'));
  };

  function k_combinations(set, k) {
    var i, j, combs, head, tailcombs;

    if (k > set.length || k <= 0) {
      return [];
    }

    if (k === set.length) {
      return [set];
    }

    if (k === 1) {
      combs = [];
      for (i = 0; i < set.length; i++) {
        combs.push([set[i]]);
      }
      return combs;
    }

    combs = [];
    for (i = 0; i < set.length - k + 1; i++) {
      head = set.slice(i, i + 1);
      tailcombs = k_combinations(set.slice(i + 1), k - 1);
      for (j = 0; j < tailcombs.length; j++) {
        combs.push(head.concat(tailcombs[j]));
      }
    }
    return combs;
  }
  function zoomTo(points, speed, type, zoom) {
    let from, to, distance, midpoint, res, metersPerPixel, zoomLevel;
    let vw = viewport;
    let options = { units: "kilometers" };
    // console.log(flInterval)
    // clearInterval(flInterval);
    // setFlInterval(prev => 0);
    switch (points.length) {
      case 0:
        setCenter([]);
        break;

      case 1:
        vw.latitude = points[0][1];
        vw.longitude = points[0][2];
        vw.transitionDuration = speed;
        zoom === 11 ? (vw.zoom = 15) : (vw.zoom = zoom);
        vw.transitionInterpolator = new FlyToInterpolator();
        vw.transitionEasing = type;
        setCenter([vw.latitude, vw.longitude]);
        setViewport(vw);
        break;

      case 2:
        from = turf.point([points[0][1], points[0][2]]);
        to = turf.point([points[1][1], points[1][2]]);

        distance = turf.distance(from, to, options) * 1000; // * 1km = 1000m

        midpoint = turf.midpoint(from, to);

        res = Math.sqrt(window.innerHeight * window.innerWidth);

        metersPerPixel = distance / res;

        zoomLevel = 0;

        for (let z = 0; z < zoomLevels.length; z++) {
          if (z < zoomLevels.length - 1) {
            if (
              zoomLevels[z][1] > metersPerPixel &&
              zoomLevels[z + 1][1] < metersPerPixel
            ) {
              zoomLevel = zoomLevels[z][0] - zoomLevels[z][0] * 0.03;

              break;
            }
          }
        }

        vw.latitude = midpoint.geometry.coordinates[0];
        vw.longitude = midpoint.geometry.coordinates[1];
        zoom === zoomDef
          ? (vw.zoom = zoomLevel)
          : zoom > zoomLevel
          ? (vw.zoom = zoomLevel)
          : (vw.zoom = zoom);
        vw.transitionDuration = 1000;
        vw.transitionInterpolator = new FlyToInterpolator();
        vw.transitionEasing = type;

        setCenter([vw.latitude, vw.longitude]);
        setViewport(vw);
        // console.log(vw);
        // console.log(   "meters/pixel:",   metersPerPixel,   "distance in m:",   distance,   "midpoint: ",   midpoint.geometry.coordinates,   "res:",   res );
        break;

      default:
        let coordinates = [];
        for (let a = 0; a < points.length; a++) {
          coordinates.push([points[a][1], points[a][2]]);
        }

        let polygon = turf.points(coordinates);
        let center = turf.center(polygon);

        let distances = [];
        let combi = k_combinations(coordinates, 2);
        // console.log(combi)
        for (let k = 0; k < combi.length; k++) {
          let arr = combi[k];
          distance = turf.distance(arr[0], arr[1], options) * 1000; // * 1km = 1000m
          distances.push(distance);
        }
        let max_distance = Math.max(...distances);

        res = Math.sqrt(window.innerHeight * window.innerWidth);

        metersPerPixel = max_distance / res;
        zoomLevel = 0;

        for (let z = 0; z < zoomLevels.length; z++) {
          if (z < zoomLevels.length - 1) {
            if (
              zoomLevels[z][1] > metersPerPixel &&
              zoomLevels[z + 1][1] < metersPerPixel
            ) {
              zoomLevel = zoomLevels[z][0] - zoomLevels[z][0] * 0.03;
              break;
            }
          }
        }
        vw.latitude = center.geometry.coordinates[0];
        vw.longitude = center.geometry.coordinates[1];

        zoom === zoomDef
          ? (vw.zoom = zoomLevel)
          : zoom > zoomLevel
          ? (vw.zoom = zoomLevel)
          : (vw.zoom = zoom);
        vw.transitionDuration = 1000;
        vw.transitionInterpolator = new FlyToInterpolator();
        vw.transitionEasing = type;

        setCenter([vw.latitude, vw.longitude]);
        setViewport(vw);
    }
    setFollow(points);
  }

  function sec2time(timeInSeconds) {
    timeInSeconds = timeInSeconds.toString();
    timeInSeconds =
      timeInSeconds[0] === "-" ? timeInSeconds.substring(1) : timeInSeconds;
    var pad = function (num, size) {
        return ("000" + num).slice(size * -1);
      },
      time = parseFloat(timeInSeconds).toFixed(3),
      hours = Math.floor(time / 60 / 60),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60);

    return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
  }

  const followHandler = (ev) => {
    ev.target.id === "off" ? (ev.target.id = "on") : (ev.target.id = "off");
    let text = ev.target.value;
    let devID = "";
    let lat = 0.0;
    let long = 0.0;
    for (let i = 0; i < 3; i++) {
      let comma = text.toString().indexOf(",");
      if (i === 0) {
        devID = text.toString().slice(0, comma);
        text = text.toString().slice(comma + 1, text.length);
      }
      if (i === 1) {
        lat = parseFloat(text.toString().slice(0, comma));
        text = text.toString().slice(comma + 1, text.length);
      }
      if (i === 2) {
        long = parseFloat(text.toString().slice(0));
      }
    }
    let arr = [devID, lat, long];
    if (ev.target.id === "on") {
      setFollow(follow.push(arr));
      zoomTo(follow, 2000, d3.easePolyIn, zoomL);
    } else {
      let ind;
      for (let x = 0; x < follow.length; x++) {
        if (follow[x][0] === arr[0]) {
          ind = x;
        }
      }
      setZoomL(11);
      setFollow(follow.splice(ind, 1));
      zoomTo(follow, 3000, d3.easePolyIn, zoomL);
    }
  };

  const createMarkers = (teamsX, status, infos) => {
    setTeams(teamsX);
    const el = [];
    const dim = 30;
    for (let i = 0; i < teamsX.length; i++) {
      el.push(
        <Marker
          key={teamsX[i][1]}
          latitude={parseFloat(teamsX[i][2])}
          longitude={parseFloat(teamsX[i][3])}
          offsetTop={-dim / 2}
          offsetLeft={-dim / 2}
        >
          <img
            key={i}
            src={`/assets/team_numbers/${infos[i][1]}.svg`}
            alt={teamsX[i][1]}
            style={{ height: dim, width: dim, opacity: 0.95 }}
          ></img>
        </Marker>
      );
      for (let x = 0; x < follow.length; x++) {
        let newFollow = [];
        if (teamsX[i][1] === follow[x][0]) {
          newFollow = follow;
          newFollow[x][1] = parseFloat(teamsX[i][2]);
          newFollow[x][2] = parseFloat(teamsX[i][3]);
          setFollow(newFollow);
        }
      }
    }
    setMarkers(el);
  };
  const showTeamPage = (team) => {
    setTpProps(team);
    setOpen(true);
    body.device_id = team[1];
    axios
      .post(api_credentials.url + "/team", body, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        // window.alert(res.data.Data);
        setTeamInfo(res.data.Data);
      });
  };
  const satelliteHandle = () => {
    const _style = getObject(mapStyles, acutalMap);
    setMapStyle((oldStyle) =>
      oldStyle === _style.classic ? _style.satellite : _style.classic
    );
    bottomBar === bottomBarImg
      ? setBottomBar(bottomBarWhiteImg)
      : setBottomBar(bottomBarImg);
    satelliteStyle === satelliteOff
      ? setSatelliteStyle(satelliteOn)
      : setSatelliteStyle(satelliteOff);
  };
  const hideHandle = () => {
    const table = document.querySelector(".table-container");
    const arrowTable = document.querySelector(".arrow-table");
    if (!isHidden) {
      table.style.left = "-800px";
      arrowTable.style.transform = "rotateY(0deg)";
      arrowTable.style.backgroundPosition = "0px center";
    } else {
      table.style.left = "0px";
      arrowTable.style.transform = "rotateY(-180deg)";
      arrowTable.style.backgroundPosition = "25px center";
    }
    setIsHidden(!isHidden);
  };
  const driverListHandle = () => {
    window.open("https://drivers.ecogp.eu", "_blank");
  };
  const phoneSponsor = () => {
    window.open(PHONE_SPONSOR_LINK, "_blank");
  };
  const FullScreenHandle = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
    let vw = viewport;
    if (isMobile) {
      vw.height = document.documentElement.clientHeight;
      vw.width = document.documentElement.clientWidth;
    } else {
      vw.height = window.innerHeight;
      vw.width = window.innerWidth;
    }
    setViewport(vw);
  };
  const loadHandler = () => {
    window.scrollTo(0, 9999);
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 1000);
  };
  const mouseDownHandle = () => {
    console.log("down");
    handleMarkersAnimanation(false);
  };
  const mouseUpHandle = () => {
    handleMarkersAnimanation(true);
  };
  // const [testMove, setTestMove] = useState({
  //   lat: 50.24573632755954,
  //   long: 15.837449392590088
  // })
  return (
    <Fragment>
      {loading ? (
        <Fragment>
          <ReactMapGL
            className={"map"}
            mapboxApiAccessToken={
              "pk.eyJ1IjoiZWNvZ3B0ZXN0IiwiYSI6ImNrOGcxdjF4djAwZmozZm84N3gwcTg4OGkifQ.uzL5ggvkW4n_-ShLoydE2g"
            }
            mapStyle={mapStyle}
            {...viewport}
            attributionControl={false}
            onViewportChange={(nextViewport) => {
              let vw = viewport;
              vw = { ...nextViewport };

              // if (follow.length === 0){
              //   vw.zoom = nextViewport.zoom
              // }
              // console.log(vw.zoom)

              // if (
              //   viewport.latitude !== nextViewport.latitude &&
              //   viewport.longitude !== nextViewport.longitude
              // ) {
              //   handleMarkersAnimanation(false);
              //   setTimeout(() => {
              //     handleMarkersAnimanation(true);
              //   }, 500);
              // }

              vw.height = document.documentElement.clientHeight;
              vw.width = document.documentElement.clientWidth;

              setViewport(vw);

              // handleMarkersAnimanation(true)
            }}
            onMouseDown={mouseDownHandle}
            onMouseUp={mouseUpHandle}
            onWheel={onScroll}
            onClick={FullScreenHandle}
            ref={mapRef}
          >
            {markers}
            {/* <Marker
                      latitude={testMove.lat}
                      longitude={testMove.long}
                      
            >

<img
            src={`/assets/team_numbers/30.svg`}
            alt={'30'}
            style={{ height: 30, width: 30, opacity: 0.95 }}
          ></img>
            </Marker> */}
          </ReactMapGL>
          <div className="buttons-container">
            <button
              onClick={satelliteHandle}
              className="satelliteButton"
              style={{ backgroundImage: `url(${satelliteStyle})` }}
              title={"Satelite Mode"}
            ></button>
            {/* <button
              onClick={driverListHandle}
              className="satelliteButton"
              style={{ backgroundImage: `url(${driversList})` }}
              title={"Drivers Page"}
            ></button> */}
          </div>
          <div className="ranking-phone"></div>
          <div className="table-container">
            <table className={"table-devices"}>
              <tbody>
                <tr key={"header"} className={"devices-row"}>
                  <th className={"rank-tb"}>
                    {window.innerWidth < 550 ? "rnk" : "rank"}{" "}
                  </th>
                  <th>{window.innerWidth < 550 ? "tm" : "team"}</th>
                  <th>Laps</th>
                  <th>PP</th>
                  <th>#</th>
                  <th>{window.innerWidth < 550 ? "ctry" : "country"}</th>
                  <th>Car</th>
                  <th>{window.innerWidth < 550 ? "dst" : "distance"}</th>
                  <th>{window.innerWidth < 550 ? "flw" : "follow"}</th>
                  <th className="team-page-th">
                    {window.innerWidth < 550 ? "inf" : "info"}
                  </th>
                </tr>

                {teams?.map((team, i) => {
                  return (
                    <tr key={team[1]} className={"devices-row"}>
                      <td key={65 * i} className={"devices-col-no rank"}>
                        {(i + 1).toString()}
                      </td>

                      <td key={4401 * (i + 1)} className={"devices-col"}>
                        {teamsInfos[i] === undefined ? "..." : teamsInfos[i][0]}
                      </td>
                      <td key={232 * (i + 1)} className={"devices-col"}>
                        {team[10]}
                      </td>
                      <td key={552 * (i + 1)} className={"devices-col"}>
                        {penaltyPoints[i] === undefined
                          ? "..."
                          : penaltyPoints[i][0]}
                      </td>
                      <td
                        key={551 * (i + 1)}
                        className={"devices-col"}
                        style={{
                          backgroundImage: `url('/assets/team_numbers/${
                            teamsInfos[i] === undefined
                              ? "01"
                              : teamsInfos[i][1]
                          }.svg')`,
                        }}
                      ></td>
                      <td
                        key={4403 * (i + 1)}
                        className={"devices-col"}
                        style={{
                          backgroundImage: `url('/assets/flags/${
                            teamsInfos[i] === undefined
                              ? "Germany"
                              : teamsInfos[i][2]
                          }.svg')`,
                        }}
                      ></td>
                      <td
                        key={4404 * (i + 1)}
                        className={"devices-col"}
                        style={{
                          backgroundImage: `url('/assets/cars/${
                            (teamsInfos[i] === undefined
                              ? "Tesla"
                              : teamsInfos[i][3]
                            ).indexOf(" ") !== -1
                              ? (teamsInfos[i] === undefined
                                  ? "Tesla"
                                  : teamsInfos[i][3]
                                ).substring(
                                  0,
                                  (teamsInfos[i] === undefined
                                    ? "Tesla"
                                    : teamsInfos[i][3]
                                  ).indexOf(" ")
                                )
                              : teamsInfos[i] === undefined
                              ? "Tesla"
                              : teamsInfos[i][3]
                          }.svg')`,
                        }}
                      ></td>
                      <td key={411 * (i + 1)} className={"devices-col km"}>
                        {(team[10] * CIRCUIT_LENGTH).toFixed(2)}
                        {/* {team[7].toString().substring(0, 2) >= 10
                          ? team[7].toString().substring(0, 3) >= 100
                            ? team[7].toString().substring(0, 4) >= 1000
                              ? team[7].toString().substring(0, 5) >= 10000
                                ? team[7].toString().substring(0, 6) >= 100000
                                  ? team[7].toString().substring(0, 8)
                                  : team[7].toString().substring(0, 7)
                                : team[7].toString().substring(0, 6)
                              : team[7].toString().substring(0, 5)
                            : team[7].toString().substring(0, 4)
                          : team[7].toString().substring(0, 3)} */}
                      </td>
                      <td key={512 * (i + 1)} className={"devices-col-btn"}>
                        <button
                          key={team[1]}
                          name={team[1]}
                          className={"follow-button"}
                          id={"off"}
                          value={[team[1], team[2], team[3]]}
                          onClick={followHandler}
                        ></button>
                      </td>
                      <td className="info-container">
                        <button
                          onClick={() => showTeamPage(team)}
                          className={"info-button"}
                        ></button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="hide-table">
              <div className="arrow-table" onClick={hideHandle}></div>
            </div>
            <div className="footer-table"></div>
            <div className="sponsor-phone" onClick={phoneSponsor}></div>
          </div>
          <Footer
            bottomStyle={bottomBar}
            eventName={raceName}
            flagColor={raceFlag}
            eventTicker={ticker}
            eventTime={[raceTimeText, raceTime]}
          ></Footer>
          <div className="phone-extension"></div>
          <TeamPage
            {...tpProps}
            open={open}
            setOpen={setOpen}
            teamInfo={teamInfo}
            raceFlag={raceFlag}
          ></TeamPage>
          {/* <div style={{
            position:'absolute',
            top:200,
            right:50,
            zIndex:999
          }}>
            <button style={{ padding:10, fontSize:20}}  onClick={(e)=>setTestMove(state=>{return {...state, lat:state.lat+0.001}})}> change lat+, {testMove.lat.toFixed(4)}</button>
            <button style={{ padding:10, fontSize:20}}   onClick={(e)=>setTestMove(state=>{return {...state, long:state.long+0.001}})}>change long+, {testMove.long.toFixed(4)}</button>
           
            <button style={{ padding:10, fontSize:20}}  onClick={(e)=>setTestMove(state=>{return {...state, lat:state.lat-0.001}})}> change lat-, {testMove.lat.toFixed(4)}</button>
            <button style={{ padding:10, fontSize:20}}   onClick={(e)=>setTestMove(state=>{return {...state, long:state.long-0.001}})}>change long-, {testMove.long.toFixed(4)}</button>
          </div> */}
        </Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </Fragment>
  );
}

export default Map;
