/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from "react";
import { useState } from "react";
import "./styles/teamPage.css";
import Dialog from "@mui/material/Dialog";
import { api_credentials } from "../var.js";
import axios from "axios";
import Slide from "@mui/material/Slide";
import driverPhoto from "../assets/images/assets/driver.jpg";
import LoadingSpinner from "./LoadingSpinner";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function TeamPage(props) {
  const ppDuration = 10; // 1pp = ppDuration in sec
  function sec2time(timeInSeconds) {
    timeInSeconds = timeInSeconds.toString();
    timeInSeconds =
      timeInSeconds[0] === "-" ? timeInSeconds.substring(1) : timeInSeconds;
    var pad = function (num, size) {
        return ("000" + num).slice(size * -1);
      },
      time = parseFloat(timeInSeconds).toFixed(3),
      hours = Math.floor(time / 60 / 60),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60);

    return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
  }
  const body = {
    key: api_credentials.key,
    api_user: api_credentials.user,
  };
  const [teamInfo, setTeamInfo] = useState(["a", "b", "c", "d", "e", "f"]);
  const [actualDriver, setActualDriver] = useState(
    new Array(100).fill("loading ...")
  );
  const [drivers, setDrivers] = useState(new Array(100).fill("loading ..."));
  const [inv1, setInv1] = useState(0);
  const [indexStart, setIndexStart] = useState(0);
  const [indexFinish, setIndexFinish] = useState(6);
  const [raceTime, setRaceTime] = useState();
  const [raceTimeText, setRaceTimeText] = useState("Race Time");
  const [laps, setLaps] = useState(0);
  const [pp, setPp] = useState(new Array(100).fill("loading ..."));
  const [ranks, setRanks] = useState(new Array(100).fill(0));
  const [order123, setOrder123] = useState(
    new Array(100).fill(new Array(100).fill(0))
  );
  const [seconds, setSeconds] = useState(0);
  const [votes, setVotes] = useState(-1);
  const [ppcheck, setPPCheck] = useState(false);
  const [ppTime, setPPTime] = useState("00:00:00");
  const [isLoading, setLoading] = useState(false);
  React.useEffect(() => {
    let interval;
    if (props.open) {
      setTeamInfo(props.teamInfo);
      body.device_id = props.teamInfo[0][1];
      body.team_number = props.teamInfo[0][4];
      interval = setInterval(() => {
        axios
          .post(api_credentials.url + "/race-info", body, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            // window.alert(res.data.Data);
            setActualDriver(res.data.Data[0]);
            setDrivers(res.data.Data[1]);
            setLaps(res.data.Data[4]);
            setPp(res.data.Data[5]);
            if (seconds !== parseInt(res.data.Data[5][2]) * ppDuration) {
              setSeconds(parseInt(res.data.Data[5][2]) * ppDuration);
            }
            setVotes(
              parseInt(res.data.Data[5][7]) === 0
                ? 1
                : parseInt(res.data.Data[5][7])
            );
            setPPCheck(res.data.Data[5][4] === "1" ? true : false);
            setRanks([res.data.Data[6], res.data.Data[7]]);
            setOrder123(res.data.Data[8]);
            setRaceTime(
              res.data.Data[2][1] === -1
                ? sec2time(res.data.Data[2][0]) + " ago."
                : res.data.Value === 1
                ? sec2time(res.data.Data[2][0])
                : sec2time(res.data.Data[2][0])
            );
            setRaceTimeText(
              res.data.Data[2][1] === -1
                ? "Race ended "
                : res.data.Value === 1
                ? "Race start in "
                : "Race time"
            );
            setLoading(true);
          });
      }, 1000);
      setInv1(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.teamInfo]);
  React.useEffect(() => {
    let sec = seconds;
    if (props.open) {
      let intervalPP = setInterval(() => {
        if (ppcheck) {
          accelereateTime(sec, ppcheck);
          sec--;
        }
      }, 1000 / (votes <= 0 ? 1 : votes));
      return () => {
        clearInterval(intervalPP);
        setLoading(false);
      };
    }
  }, [seconds, votes, ppcheck]);
  React.useEffect(() => {
    if (votes > 0) {
      setPPTime("+1 VOTE");
    }
  }, [votes]);
  function padTime(myString = "") {
    if (myString[1] === ":") {
      myString = "0" + myString;
    }
    myString = myString.substring(0, 8);
    return myString;
  }
  function checkWaitingTime(myString = "") {
    return myString.length > 7 ? true : false;
  }
  function accelereateTime(sec, ppcheck) {
    let ss = sec;
    if (ppcheck) {
      if (ss > 0) {
        let h = Math.floor(ss / 3600);
        ss = ss < 3600 ? ss : ss - 3600 * h;
        let m = Math.floor(ss / 60);
        let s = ss % 60;
        // console.log(h,m,s)
        setPPTime(
          `${h >= 10 ? "" : "0"}${h}:${m >= 10 ? "" : "0"}${m}:${
            s >= 10 ? "" : "0"
          }${s}`
        );
      }
    }
  }
  const handleClose = () => {
    clearInterval(inv1);
    setInv1(0);
    setLoading(false);
    props.setOpen(false);
  };
  const handleNext = () => {
    // console.log('start',indexStart,'finish',indexFinish)
    if (indexFinish < drivers.length) {
      setIndexFinish(indexFinish + 1);
      if (indexFinish >= 6) {
        setIndexStart(indexStart + 1);
      }
    }
  };
  const handleBack = () => {
    // console.log('start',indexStart,'finish',indexFinish)
    if (indexStart >= 0 && indexFinish > 6) {
      setIndexFinish(indexFinish - 1);
      setIndexStart(indexStart - 1);
    }
  };
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={() => setLoading(false)}
      TransitionComponent={Transition}
    >
      <div className="mBody">
        {isLoading ? (
          <React.Fragment>
            <header>
              <div className="details">
                <button
                  className="close-button"
                  onClick={() => handleClose()}
                ></button>
                <p className="team-name"> Team {teamInfo[0][4]}</p>
                <div
                  className="flag"
                  style={{
                    backgroundImage: `url('/assets/flags/${teamInfo[0][6]}.svg')`,
                  }}
                ></div>
                <p className="inside-line">-</p>
                <p className="full-name"> {teamInfo[0][2]}</p>
              </div>
              <div className="social-media">
                <a
                  className="fb"
                  rel="noreferrer"
                  href={teamInfo[0][8]}
                  target="_blank"
                ></a>
                <a
                  className="instr"
                  rel="noreferrer"
                  href={teamInfo[0][9]}
                  target="_blank"
                ></a>

                <a
                  className="yt"
                  rel="noreferrer"
                  href={teamInfo[0][10]}
                  target="_blank"
                ></a>
                <div className="share"></div>
                <div className="square"></div>
              </div>
            </header>
            <div className="second-body">
              <div className="Minfo">
                <div className="first-top">
                  <div className="driver-info">
                    <p className="name">{actualDriver[2]}</p>
                    <div
                      className="avatar"
                      alt="Pilot image"
                      style={
                        props.open
                          ? actualDriver[3] === "CHG"
                            ? {
                                backgroundImage:
                                  "url(/assets/general_photos/chg.png)",
                              }
                            : {
                                backgroundImage:
                                  "url(/assets/images/" +
                                  actualDriver[1] +
                                  "/" +
                                  actualDriver[5] +
                                  // eslint-disable-next-line no-useless-concat
                                  ".jpg), " +
                                  `url(${driverPhoto})`,
                              }
                          : {}
                      }
                    ></div>
                    {/* <p className="driver-status">PRK</p> */}
                    <div className="driving-time" id="drivingTime">
                      <p>{actualDriver[8]}</p>
                    </div>
                  </div>
                  <div className="laps">
                    <p>Laps</p>
                    <p className="laps-nr">{laps}</p>
                  </div>

                  <div className="pic">
                    <p>
                      {window.innerWidth > 760 ? "Ranking:" : "RNK "}
                      {/* <br /> {window.innerWidth < 760 ? null : "Category / Class"} */}
                    </p>
                    <p className="pic-nr">
                      {window.innerWidth < 760 ? ranks[0] : ranks[0]}
                    </p>
                  </div>
                  <div className="flag-status">
                    <p>
                      flag
                      <br />
                      status
                    </p>

                    <div
                      className="flag-status-gif"
                      style={{
                        backgroundImage:
                          'url("https://ranking.ecogp.eu/assets/images/contest_statuses/' +
                          props.raceFlag +
                          '_final.gif")',
                      }}
                    ></div>
                  </div>

                  <div className="car-class">
                    <p className="car-class-info">car class</p>
                    <div
                      className="car-logo"
                      style={{
                        backgroundImage: `url('/assets/cars/${
                          teamInfo[0][7].indexOf(" ") !== -1
                            ? teamInfo[0][7].substring(
                                0,
                                teamInfo[0][7].indexOf(" ")
                              )
                            : teamInfo[0][7]
                        }-gray.svg')`,
                      }}
                    ></div>
                    <p className="car-model">{teamInfo[0][7]}</p>
                  </div>
                  <div className="sponsor2"></div>
                  <div className="ticker-text">
                    <marquee
                      className="ticker-text-p"
                      scrollamount="5"
                      loop="infinite"
                    >
                      Welcome to ecoGP!
                    </marquee>
                  </div>
                  <div
                    className="last-lap-penalty"
                    style={
                      ppcheck
                        ? parseInt(pp[2])
                          ? { backgroundColor: "red", color: "white" }
                          : {}
                        : {}
                    }
                  >
                    <p
                      className="text"
                      style={
                        ppcheck
                          ? parseInt(pp[2])
                            ? { color: "white" }
                            : {}
                          : {}
                      }
                    >
                      Penalty
                    </p>
                    <p
                      className="penalty"
                      style={
                        ppcheck
                          ? parseInt(pp[2])
                            ? { color: "white" }
                            : {}
                          : {}
                      }
                    >
                      {pp[2]}{" "}
                      <span
                        className="penalty"
                        style={
                          ppcheck
                            ? parseInt(pp[2])
                              ? {
                                  color: "white",
                                  alignItems: "center",
                                  marginLeft: "35px",
                                  width: "120px",
                                  marginBottom: "0px",
                                  transition: "1s all ease-in-out",
                                }
                              : {}
                            : {}
                        }
                      >
                        {ppcheck ? (parseInt(pp[2]) === 0 ? "" : ppTime) : ""}
                      </span>
                    </p>
                  </div>
                  <div className="total-penalty">
                    <p>
                      {ppcheck
                        ? window.innerWidth < 760
                          ? "PP Votes"
                          : "Penalty Votes"
                        : window.innerWidth < 760
                        ? "👍 Votes"
                        : "Like Votes"}
                    </p>
                    <p className="total">{ppcheck ? pp[7] : pp[5]}</p>
                  </div>
                  <div className="like-rank">
                    <p className="rank-info">like rank</p>
                    <p className="rank-like">{ranks[1]}</p>
                    <p className="votes">[{pp[6]} votes]</p>
                  </div>
                  <div className="time-left">
                    <p>{raceTimeText}</p>
                    <p className="left">{raceTime}</p>
                  </div>
                  <div className="qr">
                    <div className="main-qr-sms">
                      <div className="qr-img"></div>
                      <div className="sms-code-container">
                        <p className="sms-code-title">team suport sms</p>
                        <p className="sms-code">ecogp vote app</p>
                      </div>
                    </div>
                    <a className="more-info">
                      <p>download for android</p>
                    </a>
                  </div>

                  <div className="second-top">
                    <div
                      className="behind-you"
                      style={order123[0] === -1 ? { display: "none" } : {}}
                    >
                      <p className="behind-team">
                        {order123[0] === -1 ? 0 : order123[0][0]}
                      </p>
                      <div className="rank">
                        <p className="behind-rank">
                          {order123[0] === -1 ? 0 : parseInt(ranks[0]) + 1}
                        </p>
                      </div>
                      <div className="behind-distance">
                        <p></p>
                      </div>
                      <div
                        className="behind-car"
                        style={{
                          backgroundImage: `url('/assets/images/${order123[0][1]}/car.jpg')`,
                        }}
                      ></div>

                      <div className="behind-battery">
                        <div className="behind-img-battery">
                          <p className="behind-battery-percent"></p>
                        </div>
                      </div>
                    </div>
                    <div className="you">
                      <p className="my-team">{order123[1][0]}</p>
                      <div className="rank">
                        <p className="my-rank">{ranks[0]}</p>
                      </div>
                      <div
                        className="my-car"
                        style={{
                          backgroundImage: `url('/assets/images/${teamInfo[0][4]}/car.jpg')`,
                        }}
                      ></div>
                      <div
                        className="arrow-right"
                        style={order123[2] === -1 ? { display: "none" } : {}}
                      ></div>
                      <div
                        className="arrow-left"
                        style={order123[0] === -1 ? { display: "none" } : {}}
                      ></div>
                      <div className="my-battery">
                        <p className="my-battery-percent"></p>
                      </div>
                    </div>
                    <div
                      className="next-to-you"
                      style={order123[2] === -1 ? { display: "none" } : {}}
                    >
                      <p className="next-team">
                        {order123[2] === -1 ? 0 : order123[2][0]}
                      </p>
                      <div className="rank">
                        <p className="next-rank">
                          {order123[2] === -1 ? 0 : parseInt(ranks[0]) - 1}
                        </p>
                      </div>
                      <div className="next-distance">
                        <p></p>
                      </div>
                      <div
                        className="next-car"
                        style={{
                          backgroundImage: `url('/assets/images/${order123[2][1]}/car.jpg')`,
                        }}
                      ></div>
                      <div className="next-battery">
                        <div className="next-img-battery">
                          <p className="next-battery-percent"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="second-inner">
                  <div className="carousel">
                    <div className="left-arrow" onClick={handleBack}></div>

                    {drivers.map((driver, i) => {
                      if (i >= indexStart && i < indexFinish) {
                        return (
                          <div className="driver" key={i}>
                            <p className="driver-name">
                              {driver[2].substring(0, driver[2].indexOf(" "))}
                              <br />{" "}
                              {driver[2].substring(
                                driver[2].indexOf(" "),
                                driver[2].length
                              )}
                            </p>
                            <div
                              className="driver-avatar"
                              alt="Pilot Image"
                              style={
                                props.open
                                  ? driver[3] === "CHG"
                                    ? {
                                        backgroundImage:
                                          "url(/assets/general_photos/chg.png)",
                                      }
                                    : {
                                        backgroundImage:
                                          "url(/assets/images/" +
                                          driver[1] +
                                          "/" +
                                          driver[5] +
                                          // eslint-disable-next-line no-useless-concat
                                          ".jpg), " +
                                          `url(${driverPhoto})`,
                                      }
                                  : {}
                              }
                            ></div>
                            <div className="bar">
                              <p className="timer">{padTime(driver[8])}</p>
                            </div>

                            <div className="s-bar">
                              <p
                                className="second-timer"
                                style={
                                  driver[10] === "true"
                                    ? { backgroundColor: "green" }
                                    : checkWaitingTime(driver[9])
                                    ? { backgroundColor: "red" }
                                    : { display: "none" }
                                }
                              >
                                {driver[10] === "true"
                                  ? padTime(driver[6])
                                  : padTime(driver[9])}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <div className="right-arrow" onClick={handleNext}></div>
                  </div>
                </div>
              </div>
              <div
                className="main-img"
                style={
                  props.open
                    ? {
                        backgroundImage:
                          "url(/assets/images/" +
                          teamInfo[0][4] +
                          "/team.jpg), " +
                          "url(/assets/general_photos/team.jpg)",
                      }
                    : {}
                }
                alt="Team image"
              ></div>
              <div className="sponsor"></div>
              <div className="motivation">
                <p className="motivation-info">Team motivation</p>
                <p className="info-motivation">{teamInfo[0][11]}</p>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </Dialog>
  );
}

export default TeamPage;
