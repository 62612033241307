/* eslint-disable jsx-a11y/no-distracting-elements */
import * as React from "react";
import "./styles/Footer.css";
import Marquee from "react-fast-marquee";
export default function Footer(props) {
  return (
    <div
      className="footer"
      style={{ backgroundImage: `url(${props.bottomStyle})` }}
    >
      <div className="flag-bottom" style={{
        backgroundImage: `url('/assets/contest_statuses/${props.flagColor}_final.gif')`
      }}></div>
      <div className="event-info-bottom">
        {window.innerWidth > 724 ? props.eventName : ""}
        <br/>{props.eventTime[0]} {props.eventTime[1]}
      </div>
      <Marquee className="ticker-info-bottom" speed={50} loop={0} gradient={false} style={{
        fontSize:20,
        visibility: window.innerWidth > 464 ? "visible" : "hidden"
      }}>
        {props.eventTicker}
      </Marquee>
    </div>
  );
}
